import React from 'react'
import { graphql } from 'gatsby'
import CommunityGuidelinesPage from '../community-guidelines'

const CommunityGuidelinesEs = ({ data, ...props }) => {
  return (
    <CommunityGuidelinesPage
      {...props}
      data={{
        ...data,
        allCommunityGuidelinesJson: data.allCommunityGuidelinesEsJson,
      }}
    />
  )
}

export const query = graphql`
  query {
    banner: file(relativePath: { eq: "banner-community.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
    }
    imageSections: allFile(
      filter: { name: { regex: "/^guidelinesImage*/" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          childImageSharp {
            id
            fluid(maxWidth: 1200, quality: 100) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
    allCommunityGuidelinesEsJson {
      edges {
        node {
          bannerHeadline
          heroHeading
          introSection {
            title
            titleColor
            header
            headerColor
            bodyColor
            body1
            body2
            body3
            button
            storeButton
            buttonLink
            buttonLabel
            buttonColor
            buttonFocus
            triangle
            bg
            textAlign
          }
          secondarySection {
            title
            titleColor
            header
            headerColor
            bodyColor
            body1
            body2
            body3
            button
            storeButton
            buttonLink
            buttonLabel
            buttonColor
            buttonFocus
            triangle
            bg
            textAlign
          }
          imageSection1 {
            containerColor
            urlDescription
            title
            flip
            reverse
            button
            storeButton
            buttonLabel
            buttonColor
            buttonLink
            focus
            src
            box
            srcDescription
            titleColor
            firstBody
            secondBody
            thirdBody
          }
          imageSection2 {
            containerColor
            urlDescription
            title
            flip
            reverse
            button
            storeButton
            buttonLabel
            buttonColor
            buttonLink
            focus
            src
            box
            srcDescription
            titleColor
            firstBody
            secondBody
            thirdBody
          }
          appGuidelines {
            title
            body
          }
          appDisclaimer
          roadGuidelines {
            title
            body
          }
          pageTitle
          pageDescription
          socialTitle
          socialDescription
          socialImage
          tabber {
            title
          }
        }
      }
    }
  }
`

export default CommunityGuidelinesEs
