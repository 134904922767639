import React from 'react'
import * as ds from '../uiLibrary'
import styled from 'styled-components'
import { H3, H4, PLarge } from '../uiLibrary/Typographyv2'
import { fontSize } from 'styled-system'

const StyleP = styled(H3)`
  display: inline-block;
  border-bottom: solid;
  border-bottom-width: 4px;
  margin-right: ${ds.spacing.s4};
  border-bottom-color: ${props =>
    props.isActive ? ds.brandColors.mediumBlue : `transparent`};
  cursor: pointer;
  ${fontSize};
`

const Guideline = props => (
  <div key={props.i}>
    <H4 color={ds.brandColors.lightBlue} fontSize={ds.typeScale.t5}>
      <i className="icon ion-md-checkmark-circle-outline" /> &nbsp;
      {props.title}
    </H4>
    <PLarge color={ds.brandColors.typography.light}>{props.body}</PLarge>
    <br />
  </div>
)

export default class Guidelines extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      strings: props.appStrings,
      isActive: 0,
    }
  }

  render() {
    return (
      <div>
        <ds.FixedWidthRow pb={ds.spacing.s3}>
          <ds.Column span={12} display={'flex'}>
            {(this.props.tabber || []).map((t, i) => (
              <StyleP
                id={i.toString()}
                key={i}
                color={ds.brandColors.typography.dark}
                isActive={this.state.isActive === i}
                onClick={this._handleClick}
                fontSize={[ds.typeScale.t5, ds.typeScale.t6]}
              >
                {t.title}
              </StyleP>
            ))}
          </ds.Column>
        </ds.FixedWidthRow>
        <ds.FixedWidthRow
          style={{
            backgroundColor: '#202020',
            transform: 'rotate(-4deg) skew(-4deg)',
          }}
          py={ds.spacing.s5}
        >
          <ds.Column
            mx={[ds.spacing.s3, ds.spacing.s5]}
            style={{ transform: 'rotate(4deg) skew(4deg)' }}
          >
            {this.state.strings.map((g, i) => (
              <Guideline {...g} key={i} />
            ))}
          </ds.Column>
        </ds.FixedWidthRow>
      </div>
    )
  }

  _handleClick = e => {
    const id = e.target.id
    if (!id) {
      return console.warn('No id for tab switcher')
    }
    if (id === '0') {
      return this.setState({
        strings: this.props.appStrings,
        isActive: 0,
      })
    }
    if (id === '1') {
      return this.setState({
        strings: this.props.roadStrings,
        isActive: 1,
      })
    }
  }
}
