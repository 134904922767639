import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layouts'
import { GatsbyHeroImage } from '../components/heroes'
import { Headline, PLarge } from '../uiLibrary/Typographyv2'
import * as ds from '../uiLibrary'
import { InfoContainerSection, GatsbyImageSection } from '../components'
import Guidelines from '../components/Guidelines'
import HeadMeta from '../components/HeadMeta'
import { LocationContext } from '../pages/index'
import { useLocale } from '../hooks/useLocale'
import { Banner } from '../components/Banner'

export const CommunityGuidelinesPage = ({ data, ...props }) => {
  const page = data.allCommunityGuidelinesJson.edges[0].node
  const bannerImage = data.banner.childImageSharp
  const imageSectionOne = data.imageSections.edges[0].node.childImageSharp
  const imageSectionTwo = data.imageSections.edges[1].node.childImageSharp

  const currentLocale = useLocale(props.location.pathname)

  // v2 banner doesn't have embed text in image
  const BannerImage = page.bannerHeadline ? Banner : GatsbyHeroImage

  return (
    <LocationContext.Provider value={props.location}>
      <Layout locale={currentLocale}>
        <HeadMeta
          pageTitle={page.pageTitle || 'Community Guidelines - Tonit'}
          pageDescription={page.pageDescription}
          socialTitle={page.socialTitle}
          socialDescription={page.socialDescription}
          socialImage={page.socialImage}
        />
        <ds.Background background={'#000000'}>
          <ds.FixedWidthContainer
            py={[ds.spacing.s5, ds.spacing.s6, ds.spacing.s6]}
          >
            <BannerImage
              url={bannerImage.fluid}
              headline={page.bannerHeadline}
              accent={ds.brandColors.mediumRed}
            />
            <Headline
              textAlign={'center'}
              px={[ds.spacing.s3, ds.spacing.s4, ds.spacing.s5]}
              fontSize={[ds.typeScale.t6, ds.typeScale.t7, '4.998rem']}
              color={ds.brandColors.mediumBlue}
            >
              {page.heroHeading}
            </Headline>
          </ds.FixedWidthContainer>
        </ds.Background>
        <ds.Background
          bg={'#202020'}
          pb={[ds.spacing.s2, ds.spacing.s4, ds.spacing.s6]}
        >
          <InfoContainerSection
            {...page.introSection}
            pt={[ds.spacing.s5, ds.spacing.s5, ds.spacing.s3]}
            pb={[ds.spacing.s5, ds.spacing.s5, ds.spacing.s7]}
            triangle={true}
          />
          <InfoContainerSection
            {...page.secondarySection}
            pt={[ds.spacing.s5, ds.spacing.s5, ds.spacing.s4]}
            pb={[ds.spacing.s5, ds.spacing.s5, ds.spacing.s6]}
            index={1}
          />
          <GatsbyImageSection
            key={imageSectionOne.id}
            url={imageSectionOne.fluid}
            {...page.imageSection1}
          />
          <ds.FixedWidthContainer bg={'white'}>
            <Guidelines
              appStrings={page.appGuidelines}
              roadStrings={page.roadGuidelines}
              universalDisclaimer={page.universalDisclaimer}
              tabber={page.tabber}
            />
            <ds.FixedWidthRow pb={0} mt={ds.spacing.s4}>
              <ds.Column span={12} textAlign="center">
                <PLarge fontSize={[ds.typeScale.t4, ds.typeScale.t5]}>
                  {page.appDisclaimer}
                </PLarge>
              </ds.Column>
            </ds.FixedWidthRow>
          </ds.FixedWidthContainer>
          <GatsbyImageSection
            key={imageSectionTwo.id}
            url={imageSectionTwo.fluid}
            {...page.imageSection2}
          />
        </ds.Background>
      </Layout>
    </LocationContext.Provider>
  )
}

export const query = graphql`
  query {
    banner: file(relativePath: { eq: "tonitguidelinesheader.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
    }
    imageSections: allFile(
      filter: { name: { regex: "/^guidelinesImage*/" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          childImageSharp {
            id
            fluid(maxWidth: 1200, quality: 100) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
    allCommunityGuidelinesJson {
      edges {
        node {
          bannerHeadline
          heroHeading
          introSection {
            title
            titleColor
            header
            headerColor
            bodyColor
            body1
            body2
            body3
            button
            storeButton
            buttonLink
            buttonLabel
            buttonColor
            buttonFocus
            triangle
            bg
            textAlign
          }
          secondarySection {
            title
            titleColor
            header
            headerColor
            bodyColor
            body1
            body2
            body3
            button
            storeButton
            buttonLink
            buttonLabel
            buttonColor
            buttonFocus
            triangle
            bg
            textAlign
          }
          imageSection1 {
            containerColor
            urlDescription
            title
            flip
            reverse
            button
            storeButton
            buttonLabel
            buttonColor
            buttonLink
            focus
            src
            box
            srcDescription
            titleColor
            firstBody
            secondBody
            thirdBody
          }
          imageSection2 {
            containerColor
            urlDescription
            title
            flip
            reverse
            button
            storeButton
            buttonLabel
            buttonColor
            buttonLink
            focus
            src
            box
            srcDescription
            titleColor
            firstBody
            secondBody
            thirdBody
          }
          appGuidelines {
            title
            body
          }
          appDisclaimer
          roadGuidelines {
            title
            body
          }
          pageTitle
          pageDescription
          socialTitle
          socialDescription
          socialImage
          tabber {
            title
          }
        }
      }
    }
  }
`

export default CommunityGuidelinesPage
